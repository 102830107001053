import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "./about.css"

const aboutPage = ({ data }) => {
  const { introSection, bios, endSection } = data.pageContent

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.description}
            className="image"
          />
        )
      },
    },
  }

  return (
    <Layout>
      <Seo title="Home" keywords={[`queer`, `care`, `stress`, 'lgbtq2']} />
      <div className="about">
        <section className="intro">{renderRichText(introSection)}</section>
        <section className="authors">{renderRichText(bios, options)}</section>
        <section className="funder">
          {renderRichText(endSection, options)}
        </section>
      </div>
    </Layout>
  )
}
export default aboutPage

export const query = graphql`
  query {
    pageContent: contentfulAboutPage {
      introSection {
        raw
      }
      bios {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            description
          }
        }
      }
      endSection {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            description
          }
        }
      }
    }
  }
`
